<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <!-- <CarrotTitle title="전략마케팅 처리현황">
            - Inbound (고객으로부터 온 전화를 받았을 때)<br>
            - Outbound (고객 혹은 잠재고객에게 능동적으로 전화를 걸었을 때)
        </CarrotTitle> -->
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <!-- <tr>
                                <th><span class="txt-red">*</span> 분류</th>
                                <td colspan="3">
                                    <select v-model="add.category" class="w-200px">
                                        <option value="">분류 전체</option>
                                        <option value="INBOUND">Inbound</option>
                                        <option value="OUTBOUND">Outbound</option>
                                        <option value="B2G">B2G(나라장터)</option>
                                        <option value="PARTNER">제휴관련</option>
                                        <option value="ETC">기타</option>
                                    </select>
                                </td>
                            </tr> -->
                            <tr>
                                <th><span class="txt-red">*</span> 회사명</th>
                                <td>
                                    <p v-if="!add.is_company_edit" class="float-left">{{ add.company }}</p><button v-if="!add.is_company_edit" @click="add.companyMod" class="btn-default float-left ml-10">회사검색</button>
                                    <CarrotCompany v-if="add.is_company_edit" v-model:companyname.sync="add.company" v-model="add.idx_company" class="float-left w-290px"></CarrotCompany>
                                    <button v-if="add.is_company_edit" @click="add.companyReg" class="btn-default float-left ml-10">신규등록</button>
                                </td>
                                <th><span class="txt-red">*</span> 담당자</th>
                                <td>
                                    <input type="text" v-model.trim="add.manager" class="w-300px">
                                </td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>
                                    <input type="text" v-model.trim="add.phone" class="w-300px">
                                </td>
                                <th>E-mail</th>
                                <td>
                                    <input type="text" v-model.trim="add.email" class="w-300px">
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td colspan="3">
                                    <carrot-address v-model:zipcode.sync="add.zipcode" v-model:address.sync="add.addr"></carrot-address>
                                    <input type="text" v-model.trim="add.addr_sub" class="w-100per mt-5" placeholder="상세주소를 입력하세요.">
                                </td>
                            </tr>
                            <tr>
                                <th>유입경로<br>(캐럿 매니저가 직접 입력 시<br>유입 경로 파악 부탁드립니다.)</th>  
                                <td colspan="3">
                                    <label><input type="radio" v-model="add.enterPath" value="캐럿 담당자 미팅"><span class="ml-5 mr-20"> 캐럿 담당자 미팅</span></label>
                                    <label><input type="radio" v-model="add.enterPath" value="캐럿 컨퍼런스 및 설명회 등 모임"><span class="ml-5 mr-20"> 캐럿 컨퍼런스 및 설명회 등 모임</span></label>
                                    <label><input type="radio" v-model="add.enterPath" value="온라인 홍보 (HR캐스터, 이메일 홍보, 인스타그램, 유튜브, 블로그 등)"><span class="ml-5 mr-20"> 온라인 홍보 (HR캐스터, 이메일 홍보, 인스타그램, 유튜브, 블로그 등)</span></label>
                                    <br>
                                    <label><input type="radio" v-model="add.enterPath" value="인터넷 검색"><span class="ml-5 mr-20"> 인터넷 검색</span></label>
                                    <label><input type="radio" v-model="add.enterPath" value="캐럿글로벌 종합안내서 책자 및 사보 등 자료"><span class="ml-5 mr-20"> 캐럿글로벌 종합안내서 책자 및 사보 등 자료</span></label>
                                    <label><input type="radio" v-model="add.enterPath" value="타사 담당자 및 전임자 등 지인 소개"><span class="ml-5 mr-20"> 타사 담당자 및 전임자 등 지인 소개</span></label>
                                    <br>
                                    <label>
                                            <input type="radio" v-model="add.enterPath" value="기타 : 직접 입력">
                                            <span class="ml-5 mr-20"> 기타 : 직접 입력</span>
                                            <input v-if="add.enterPath === '기타 : 직접 입력'" v-model="add.enterPathText" type="text" class="w-200px">                                        
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>과정 종류</th> 
                                <td colspan="3">
                                    <label><input type="radio" v-model="add.program" value="어학"><span class="ml-5 mr-20"> 어학</span></label>
                                    <label><input type="radio" v-model="add.program" value="리더십"><span class="ml-5 mr-20"> 리더십</span></label>
                                    <label><input type="radio" v-model="add.program" value="주재원,현채인"><span class="ml-5 mr-20"> 주재원,현채인</span></label>
                                    <label><input type="radio" v-model="add.program" value="해외연수"><span class="ml-5 mr-20"> 해외연수</span></label>
                                    <label><input type="radio" v-model="add.program" value="GHR"><span class="ml-5 mr-20"> GHR</span></label>
                                    <label><input type="radio" v-model="add.program" value="전화화상"><span class="ml-5 mr-20"> 전화화상</span></label>
                                    <label><input type="radio" v-model="add.program" value="에듀테크"><span class="ml-5 mr-20"> 에듀테크</span></label>
                                    <label><input type="radio" v-model="add.program" value="밝음, 명상"><span class="ml-5 mr-20"> 밝음, 명상</span></label>
                                    <label><input type="radio" v-model="add.program" value="전반적 컨설팅"><span class="ml-5 mr-20"> 전반적 컨설팅</span></label>
                                    <label><input type="radio" v-model="add.program" value="기타"><span class="ml-5 mr-20"> 기타</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 내용</th>
                                <td colspan="3">
                                    <CarrotEditor v-model="add.contents" class="w-100per h-300px"></CarrotEditor>
                                    <!-- <textarea v-model.trim="add.contents" class="w-100per h-100px"></textarea> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="add.doCancel" class="btn-default float-left mt-30">목록</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
// import Swal from 'sweetalert2'
import CarrotAddress from '@/components/common/CarrotAddress.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'
import CarrotEditor from '@/components/common/CarrotEditor.vue'

export default {
    layout:"customerManagement",
    components: {
        CarrotCompany,
        CarrotAddress,
        CarrotEditor
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();

        const add = reactive({

            is_company_edit : false,

            mtype_list  : [],
            enterPath   : "",
            enterPathText : "",
            category    : "",
            program     : "",
            idx_company : 0,
            company     : "",
            manager     : "",
            phone       : "",
            email       : "",
            zipcode     : "",
            addr        : "",
            addr_sub    : "",
            contents    : '<p style="font-size: 10pt; color: #e03e2d;">※ 고객 Needs를 가능한 세부적으로 작성해주세요.</p>\
                            <p style="font-size: 10pt;">전달사항 기본 서식</p>\
                            <p style="font-size: 10pt;">1. 과정: </p>\
                            <p style="font-size: 10pt;">2. 언어: </p>\
                            <p style="font-size: 10pt;">3. 인원: </p>\
                            <p style="font-size: 10pt;">4. 장소: </p>\
                            <p style="font-size: 10pt;">5. 시기: </p>\
                            <p style="font-size: 10pt;">6. 특이 사항: </p>',

            companyMod : () => {
                add.is_company_edit = true;
            },
            companyReg : () => {
                window.open(`/customerManagement/customerDBAdd`, '_blank');
            },
            doSubmit : () => {
                let params = {
                    category : add.category,
                    program  : add.program,
                    idx_company : add.idx_company,                    
                    company  : add.company,
                    manager  : add.manager,
                    phone    : add.phone,
                    email    : add.email,
                    zipcode  : add.zipcode,
                    addr     : add.addr,
                    addr_sub : add.addr_sub,
                    contents : add.contents,
                    enterPath : add.enterPath,
                    enterPathText : add.enterPathText,
                };
                
                if(add.enterPath === 'etc'){
                    if(add.enterPathText === ""){
                        toast.error("직접입력 내용을 입력해주세요.");
                        return;
                    }
                }

                if( !add.company || !add.manager ){
                    toast.error("필수 입력 항목을 입력해주세요.");
                    return;
                }

                // let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                // if ( add.email == '' || !re.test(add.email) ) {
                //     toast.error("올바른 이메일 주소를 입력하세요.");
                //     return;
                // }

                if( !add.contents ){
                    toast.error("필수 입력 항목을 입력해주세요.");
                    return;
                }

                axios.post("/rest/customermgr/addSMP", params).then((res) => {
                    if( res.data.err == 0 ){
                        router.push({
                            name : "customerManagement-statusSMPList"
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doCancel: () => {
                router.go(-1);
            },

        });

        onMounted(() => {
            // Mounted
        });

        return {add};
    }
}
</script>

<style lang="scss" scoped>
</style>